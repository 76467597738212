import { ICountry } from './country';
import { ICurrency } from './currency';
import { IPrefix } from './prefix';
import { IVisitor } from './visitor';
import { IFooter } from './footer';

export enum Webapps {
  bartOld = 1,
  lisa = 2,
  maggie,
  patty,
  winchester,
  selma,
  bart = 8
}

export interface IWebapp {
  version: string;
  currencies: Array<ICurrency>;
  countries: Array<ICountry>;
  prefixes: Array<IPrefix>;
  footer: IFooter;
  visitor: IVisitor;
  features: IWebAppFeatures;
}

export interface IWebAppFeatures {
  themeSwitcher: boolean
  password: {
    recovery: {
      phone: boolean;
      email: boolean;
    }
  }
}
