import { version } from 'package.json';
import { EnvConfig } from '@bs24/core/models/environment-config';
import { Webapps } from '@bs24/core/models/webapp';

export const environment: EnvConfig = {
  currency: {id: 1, code: 'EUR', name: 'Euro', symbol: 'EUR'},
  appVersion: version,
  production: false,
  bookmakerId: 4,
  webAppId: Webapps.patty,
  project: 'betstarters.cloud',
  api: {
    endpoint: 'https://api-frontend.betstarters.cloud'
  },
  externalScripts: [],
  features: {
    registration: 'phone',
  },
  theme: {
    defaultDark: false
  },
  keys: {
    altenar: '8bet'
  },
  languages: [
    {
      code: 'en',
      code4: 'en-GB',
      name: 'English',
      isDefault: true
    }
  ],
  timeZone: 'Europe/Rome'
};
