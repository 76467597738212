import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ExternalScript } from '@bs24/core/models/externalScript';
import { EnvConfig } from '@bs24/core/models/environment-config';

/** @dynamic */

/**
 * DynamicScriptLoaderService is a service that provides methods for dynamically loading scripts into the application.
 * It is responsible for fetching and caching external scripts, and managing their loading and execution.
 */
@Injectable({
  providedIn: 'root'
})
export class DynamicScriptLoaderService {

  /**
   * The head element of the document.
   */
  readonly headTag: HTMLHeadElement;

  /**
   * Array of ScriptTag objects from the configuration.
   */
  private Tags: Array<ExternalScript> | undefined = this.config.externalScripts;

  /**
   * Record of ScriptTag objects indexed by their names.
   */
  private tags: Record<string, ExternalScript> = {};

  /**
   * Creates an instance of DynamicScriptLoaderService.
   * @param config - The environment configuration.
   * @param document - The document object.
   */
  constructor(private config: EnvConfig, @Inject(DOCUMENT) private document: Document) {
    this.Tags?.forEach(tag => {
      this.tags[tag.name] = {
        name: tag.name,
        type: tag.type,
        link: tag.link,
        defer: tag.defer,
        async: tag.async,
        options: tag.options,
        loaded: false,
        attributes: tag.attributes
      };
    });

    this.headTag = this.document.getElementsByTagName('head')[0];
  }

  /**
   * Loads multiple scripts by their names.
   * @param tags - An array of script names to load.
   * @returns An array of Observables, each emitting a ScriptTag when loaded.
   */
  load(...tags: Array<string>): Array<Observable<ExternalScript>> {
    const loadable: Array<Observable<ExternalScript>> = [];

    tags.forEach(tagName => {
      const tag = this.Tags?.find(t => t.name === tagName);

      if (tag) {
        if (tag.options?.preload) {
          tag.options.preload();
        }

        loadable.push(this.loadTag(tagName));
      }
    });

    return loadable;
  }

  /**
   * Unloads a script by its ID.
   * @param id - The ID of the script to unload.
   * @returns An Observable that emits a boolean indicating success or failure.
   */
  unload(id: string): Observable<boolean> {
    if (id in this.tags) {
      this.tags[id].loaded = false;
      this.tags[id].status = null;
    }
    return new Observable<boolean>(res => {
      const elm = this.document.getElementById(id);
      if (!elm) {
        return res.error(false);
      }
      this.headTag.removeChild(elm);
      res.next(true);
    });
  }

  /**
   * Loads a single script by its name.
   * @param name - The name of the script to load.
   * @returns An Observable that emits the ScriptTag when loaded.
   */
  loadTag(name: string): Observable<ExternalScript> {
    return new Observable<ExternalScript>(res => {
      if (!this.tags[name].loaded) {
        // load tag
        let tag: HTMLLinkElement | HTMLScriptElement;

        if (this.tags[name].type === 'text/css') {
          tag = this.document.createElement('link');
          tag.href = this.tags[name].link;
          tag.rel = 'stylesheet';

        } else {
          tag = this.document.createElement('script');
          tag.src = this.tags[name].link;
          tag.async = this.tags[name].async ?? true;
          tag.defer = this.tags[name].defer || false;
          this.tags[name].attributes?.forEach(a => {
            tag.setAttribute(a.key, a.value);
          });

          tag.onload = () => {
            this.tags[name].loaded = true;
            this.tags[name].status = 'Loaded';
            return res.next(this.tags[name]);
          };

          tag.onerror = () => {
            this.tags[name].status = 'Errored';
            return res.error(this.tags[name]);
          };
        }

        tag.type = this.tags[name].type;
        tag.id = name;

        const bl = this.tags[name].options?.beforeLoad; // if before load handle

        if (bl) {
          let toAppend: HTMLElement = tag;

          if (bl.instruction === 'create') {
            toAppend = this.document.createElement('div');
          }

          Object.entries(bl.keys).forEach(([key, val]) => toAppend.setAttribute(key, val));

          const element = bl.appendTo ? this.document.getElementById(bl.appendTo) : this.headTag;
          element?.appendChild(toAppend);
        }

        const al = this.tags[name].options?.afterLoad;

        if (al) {
          tag.onload = () => al();
        }

        this.headTag.appendChild(tag);
      } else {
        this.tags[name].status = 'Already Loaded';
        return res.next(this.tags[name]);
      }
    });
  }
}
